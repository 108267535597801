<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-start">
      <h1>Total valeur des produits en stock Bioherbs : {{ totalValue }} TND</h1>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="produits"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="
                $router.push({
                  name: 'produit-edit',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormSelect,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Nom du produit',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher produit',
          },
        },

        {
          label: 'Stock Bioherbs',
          field: 'quantity',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
        },
        {
          label: 'Prix Achat',
          field: 'purchase_price',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
        },
        {
          label: 'Stock Labo',
          field: 'quantity_labo',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
        },
        {
          label: 'Stock Total',
          field: 'quantity_total',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
        },
        {
          label: 'Valeur en TND',
          field: 'value',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Valeur',
          },
        },
      ],
      produits: [],
      searchTerm: '',
      totalValue: 0,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getProduits()
  },
  methods: {
    imprimer() {
      window.print()
    },
    // get
    async getProduits() {
      this.load = 'true'
      const { data } = await axios.get('/reporting/')
      this.produits = data.products
      this.totalValue = data.total_value
      this.load = 'false'
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @media print {
    footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: auto; /* Footer height */
    }

    .table thead th {
      border-bottom: 2px solid black;
    }

    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .print{
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }

    .contact {
      background-color: #0b6746;
      padding: 20px;
      border-radius: 25px;
      -webkit-print-color-adjust: exact;
    }
    footer footer-light footer-static {
      display: inline-block;
    }
    footer.footer {
      display: none;
    }

    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }

        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
              > .col-12 {
                max-width: 50% !important;
              }

              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }

      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
    table,
    td,
    th {
      padding: 10px;
      text-align: left;
      border: 1px solid black;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }
  }
  </style>
